$time: 500ms;
$delay: $time/2;
$mode: cubic-bezier(0.17, 0.67, 0.55, 1.43);

@keyframes heartbeat {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.gallery-carousel {
  flex: 1;
  position: relative;

  .slider-container {
    position: absolute;
    left: 50%;
    top: 0;
    width: 90%;
    margin: -0px auto 0px -45%;

    .slider-content {
      position: relative;
      left: 50%;
      top: 0;
      width: 70%;
      height: 80%;
      transform: translateX(-50%);

      .slider-single {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        transition: z-index 0ms $delay;

        .slider-single-content {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: relative;
          left: 0;
          top: 0;
          width: 100%;
          //box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
          transition: $time $mode;
          transform: scale(0);
          opacity: 0;

          img {
            max-width: 100%;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-user-drag: none;
            user-drag: none;
            -webkit-touch-callout: none;
          }
        }

        &.pre-active-ondeck {
          .slider-single-content {
            transform: translateX(-90%) scale(0);
          }
        }

        &.pre-active {
          z-index: 1;

          .slider-single-content {
            opacity: 0.3;
            transform: translateX(-90%) scale(0.7);
          }
        }

        &.post-active {
          z-index: 1;

          .slider-single-content {
            opacity: 0.3;
            transform: translateX(90%) scale(0.7);
          }
        }

        &.post-active-ondeck {
          .slider-single-content {
            transform: translateX(90%) scale(0);
          }
        }

        &.active {
          .slider-left,
          .slider-right {
            display: block;
          }

          z-index: 2;

          .slider-single-content {
            opacity: 1;
            transform: translateX(0%) scale(1);
          }
        }
      }
    }

    .slider-left {
      position: absolute;
      display: none;
      z-index: 3;
      right: 101%;
      height: 100%;
      cursor: pointer;

      div {
        position: absolute;
        top: 50%;
        left: -32px;
        color: #010100;
        font-size: 64px;
        transform: translateY(-50%);
        background-color: #e2e5e6;
        border-radius: 50%;
        height: 64px;
        width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .slider-right {
      display: none;
      position: absolute;
      z-index: 3;
      left: 101%;
      height: 101%;
      cursor: pointer;

      div {
        position: absolute;
        top: 50%;
        right: -32px;
        color: #010100;
        font-size: 64px;
        transform: translateY(-50%);
        background-color: #e2e5e6;
        border-radius: 50%;
        height: 64px;
        width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      margin-left: -2px;
    }

    .slider-disabled {
      div {
        display: none;
      }
    }
  }
}

@media (min-width:300px) and (max-width:480px) {
  .gallery-carousel {
    .slider-container {
      .slider-content {
        .slider-right {
          div {
            width: 32px;
            height: 32px;
            right: -16px;
          }
        }
        .slider-left {
          div {
            width: 32px;
            height: 32px;
            left: -16px;
          }
        }
      }
    }
  }
}
