:root {
  --black: rgba(0, 0, 0, 1);
  --white: rgba(255, 255, 255, 1);
  --grey: #F0F0F0;
  --gold: #FFC633;
  --red: rgba(255, 48, 48, .4);
  --green:  #01AB31;
  --typography-base: rgba(0, 0, 0, .6);
  --typography-contrast: rgba(255, 255, 255, 1);
  --typography-muted: rgba(0, 0, 0, .4);
  --typography-discount: rgba(255, 48, 48, 1);
  --discount-bg: rgba(255, 48, 48, .1);
  --success: #01AB31;
  --separator: rgba(0, 0, 0, .1);
	--primary: #ff3d3d;
	--primary-hover: #d02e2e;
	--secondary: #9b51e0;
	--secondary-hover: #7c40b0;
	--gradient-start: #ff3d3d;
	--gradient-end: #9b51e0;
	--light-blue: #007bff;
	color: var(--black);


	// Definitions from Breakmark
	--primary-base: #4AA98C;
	--primary-base-hover: #1F5C4A;
	--primary-base-press: #184539;
	--primary-base-selected: #307A63;
	--secondary-base: #2F4396;
	--secondary-base-hover: #1F295C;
	--secondary-base-press: #161D43;
	--secondary-base-selected: #222C72;
	--tertiary-base: #87CEEB;
	--tertiary-base-hover: #6BA3B9;
	--tertiary-base-press: #527F91;
	--tertiary-base-selected: #569FBF;
	--gray-base: #E5E7EB;
	--gray-base-hover: #D1D5DB;
	--gray-base-press: #9CA3AF;
	--gray-base-selected: #6B7280;
	--gold-base: #D4AF37;
	--gold-base-hover: #C19A2F;
	--gold-base-press: #AF8428;
	--gold-base-selected: #987221;
	--white-base: #FFFFFF;
	--white-base-hover: #F2F2F2;
	--white-base-press: #E6E6E6;
	--white-base-selected: #CCCCCC;


	// Button Colors
	--success: #01AB31;
	--success-hover: #017D21;
	--success-press: #015F19;
	--success-font: #f0f0f0;
}

.typography-base {
	color: var(--typography-base) !important;
}
.typography-muted {
	color: var(--typography-muted) !important;
}
.typography-contrast {
	color: var(--typography-contrast) !important;
}
.bold {
	font-weight: bold !important;
}

@font-face {
	font-family: 'THICCCBOI-Bold';
	src: url('../../public/fonts/THICCCBOI-Bold.woff2') format('woff2');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Satoshi-Variable';
	src:
		url('../../public/fonts/Satoshi-Variable.woff2') format('woff2'),
		url('../../public/fonts/Satoshi-Variable.woff') format('woff'),
		url('../../public/fonts/Satoshi-Variable.ttf') format('truetype');
	font-weight: 300 900;
	font-display: swap;
	font-style: normal;
}

h1 {
	font-family: 'THICCCBOI-Bold', sans-serif;
	font-size: 64px;
	font-weight: 700;
	line-height: 64px;
	margin-bottom: 32px;
	color: var(--black);
}

h2 {
	font-family: THICCCBOI-Bold, sans-serif;
	font-size: 48px;
	font-weight: 700;
	margin-bottom: 24px;
	color: var(--black);
}

h3 {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 20px;
	color: var(--black);
}

h4 {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 36px;
	font-weight: 700;
	margin-bottom: 18px;
	color: var(--black);
}

h5 {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 12px;
	color: var(--black);
}

h6 {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 10px;
	color: var(--black);
}

p {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	margin-bottom: 16px;
	color: var(--typography-base);
}

div {
	font-family: Satoshi-Variable, sans-serif;
	// font-size: 16px;
	// line-height: 22px;
	// font-weight: 400;
	color: var(--typography-base);
}

cite {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	margin-bottom: 8px;
	color: var(--typography-muted);
	font-style: normal;
}

strong {
	font-family: Satoshi-Variable, sans-serif;
	font-weight: 600;
}

small {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 14px;
	font-weight: 400;

	strong,
	b {
		font-weight: 500;
	}
}

a {
	text-decoration: none;
	font-weight: 600;
	color: var(--black);

	&.active {
		font-weight: 600;
	}

	&.contrast {
		color: var(--typography-contrast);
	}

	&:hover {
		text-decoration: underline;
	}
}

ul {
	margin-bottom: 24px;
	list-style: disc;
	margin-left: 12px;

	li {
		@extend p;
		margin-left: 12px;
		margin-bottom: 12px;
	}
}

.no-margin {
	margin: 0;
}

.no-decoration {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}

	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
}

@media only screen and (max-width: 600px) {
	h1 {
		font-size: 36px;
		margin-bottom: 18px;
		line-height: 36px;
	}
	h2 {
		font-size: 32px;
		line-height: 32px;
		margin-bottom: 16px;
	}
	h3 {
		font-size: 24px;
		margin-bottom: 12px;
	}
	h4 {
		font-size: 22px;
		margin-bottom: 12px;
	}
	h5 {
		font-size: 20px;
		margin-bottom: 12px;
	}
	h6 {
		font-size: 18px;
		margin-bottom: 12px;
	}
	cite {
		font-size: 12px;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
	h1 {
		font-size: 46px;
		margin-bottom: 23px;
		line-height: 46px;
	}
	h2 {
		font-size: 36px;
		line-height: 36px;
		margin-bottom: 18px;
	}
	h3 {
		font-size: 28px;
		margin-bottom: 14px;
	}
	h4 {
		font-size: 24px;
		margin-bottom: 12px;
	}
	h5 {
		font-size: 22px;
		margin-bottom: 12px;
	}
	h6 {
		font-size: 20px;
		margin-bottom: 12px;
	}
	cite {
		font-size: 14px;
	}
}
