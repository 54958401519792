.centered {
	text-align: center;
	justify-content: center;
}

.padded {
	padding: 64px;
}

.white-box {
	background-color: var(--white);
	color: var(--typography-base);
}

.grey-box {
	background-color: var(--grey);
	color: var(--typography-base);
}

.green-box {
	background-color: var(--success);
	color: var(--typography-contrast);
}

.gold-box{
  background-color: var(--gold);
  color: var(--typography-contrast);
}

.black-box{
  background-color: var(--black);
  color: var(--typography-contrast);

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	strong,
	small {
		color: var(--typography-contrast);
	}
}

.discounted-box {
	background-color: var(--discount-bg);
	color: var(--typography-discount);
	padding: 10px;

	p,
	strong,
	small {
		color: var(--typography-discount);
	}
}

.rounded {
	border-radius: 30px;
}

.signin-button {
	width: 205px;
	height: 40px;
	gap: 8px;
	text-transform: initial !important;
}

.snackbar-snack {
	font-family: Satoshi-Variable, sans-serif;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	margin-bottom: 16px;
}
